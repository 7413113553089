import React from "react"
import { graphql } from "gatsby"
import BlogLoop from '../components/blog/blog-post-archive-Loop';

const CaseStudyArchive = ({
  data,
  pageContext: { nextPagePath, previousPagePath, totalPages, currentPage, taxonomy },
}) => {
  const posts = data.posts.nodes
  
  return (
    <div>
      <BlogLoop
      posts={posts}
      postCat={'/case-studies/'}
      postCatOverride={true}
      nextPagePath={nextPagePath}
      previousPagePath={previousPagePath}
      totalPages={totalPages}
      currentPage={currentPage} 
      />
    </div>
  )
}

export default CaseStudyArchive

export const pageQuery = graphql`
  query CaseArchiveQuery(
      $offset: Int!
      $postsPerPage: Int!
    ) {

     

    posts: allWpCaseStudy(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset,
    ) {
      nodes {
        title
        link
        slug
        acf_caseStudyFields {
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
       
      }
    }



  }
`

import React from 'react'
import styled from 'styled-components';
import Arrow from '../../assets/button-arrow.inline.svg'

const ButtonStyled = styled.span`
position:relative;
z-index:10;
display: inline-flex;
width: auto;
flex-wrap:wrap;


    div.button{
    text-decoration:none;
    padding:10px 15px;
    font-family:var(--font-reg);
    transition:0.5s all ease-in-out;
    border:1px solid var(${(props) => props.color});
    color:var(${(props) => props.color});
    display:inline;
    overflow:hidden;
    position: relative;
    cursor: ${(props) => props.cursor};

        &:after{
        content:"";
        position:absolute;
        top:0px;
        /* top:-10px; */
        left:0;
        width:100%;
        height:100%;
        /* height:calc(100% + 20px); */
        transform:translateX(-100%);
        background:var(${(props) => props.color});
        transition:0.5s all ease-in-out;
        z-index:-1;
        }

        svg{
        margin-left: 2rem;
        
            path{
            transition:0.5s all ease-in-out;
            fill:var(${(props) => props.color});
            }

        }

        &:hover{
        color:var(${(props) => props.invert});
        border:1px solid var(${(props) => props.bg});

            svg path{
                fill:var(${(props) => props.invert});
            }
            &:after{
                transform:translateX(${(props) => props.hover});
            }
        }
    }

    &.js_hover  div.button{
    color:var(${(props) => props.invert});
    border:1px solid var(${(props) => props.bg});

        svg path{
            fill:var(${(props) => props.invert});
        }
        &:after{
            transform:translateX(${(props) => props.hover});
            background:var(${(props) => props.bg});
        }
    }
    
  
`

export default function FakeButton(data) {
    return (
        <ButtonStyled 
            className="fakeButton" 
            color={'--'+data.color} 
            bg={data.bg ? '--'+data.bg : data.color === 'white' ? '--black' : '--white' } 
            invert={data.color === 'white' ? '--black' : '--white'}
            hover={data.withHover === true ? '0' : '-100%' }
            cursor={data.withHover === true ? 'pointer' : 'normal' }
        >
            <div
            className="button"
            >
                {data.text}
                <Arrow />
            </div>
        </ButtonStyled>        
    )
}
